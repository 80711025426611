import { clsx, type ClassValue } from 'clsx';
import { format } from 'date-fns';
import { isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function apiEndpoint(endpoint: string) {
  return import.meta.env.VITE_API_URL + endpoint;
}

export function formatDateForDisplay(date: string | number | Date) {
  return format(parseDate(date), 'MMMM d, yyyy');
}

export function formatMDYDateForDisplay(date: string | number | Date) {
  return format(parseDate(date), 'MM/dd/yyyy');
}

export function formatTimeForDisplay(
  date: string | number | Date,
  seconds = false
) {
  return format(date, `h:mm${seconds ? ':ss' : ''} aa`);
}

export function formatDateTimeForDisplay(
  date: string | number | Date,
  seconds = false
) {
  return `${formatDateForDisplay(date)} ${formatTimeForDisplay(date, seconds)}`;
}

export function formatMDYDateTimeForDisplay(date: string | number | Date) {
  return `${formatMDYDateForDisplay(date)} ${formatTimeForDisplay(date)}`;
}

export function formatName(firstName: string, lastName: string) {
  return `${firstName} ${lastName}`;
}

export function formatNameAlt({
  firstName,
  lastName,
  company,
}: {
  firstName: string;
  lastName: string;
  company?: string | null | undefined;
}) {
  let name = `${firstName} ${lastName}`;
  if (company && company.length > 0) {
    name = `${name} (${company})`;
  }
  return name;
}

export const fullUserName = (u?: { name: string; company?: string | null }) => {
  if (!u) return '';

  return `${u.name}${u.company ? ` (${u.company})` : ''}`;
};

export function formatInitials(firstName: string, lastName: string) {
  return `${firstName[0]}${lastName[0]}`;
}

export function naturalSort(a: string | null, b: string | null) {
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }

  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}

export function reactElementIsPresent(element: React.ReactNode) {
  return isValidElement(element) && (element as any).type(element.props);
}

function parseDate(date: string | number | Date): Date {
  if (typeof date === 'string') {
    if (date.includes('T') || date.includes(' ')) {
      return new Date(date);
    }
    return new Date(`${date}T00:00:00`);
  }
  if (typeof date === 'number') return new Date(date);
  return date;
}
